import React from "react";
import "./style.scss";
import { icons, links } from "../../utils/assets";

const AppStoreButtons = () => {
  return (
    <div className="footer__appIconsContainer">
      <a href={links.APP_STORE} target="_blank" rel="noopener noreferrer">
        <img
          className="footer__appIcon"
          src={icons.APP_STORE}
          alt="App store"
        />
      </a>

      <a href={links.GOOGLE_PLAY} target="_blank" rel="noopener noreferrer">
        <img
          className="footer__appIcon"
          src={icons.GOOGLE_PLAY}
          alt="Play store"
        />
      </a>
    </div>
  );
};

export default AppStoreButtons;
