/* eslint-disable */
import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./style.scss";
import { getUser } from "../../utils/auth";
import { isUserSubscribed } from "../../utils/revenueCat";
import { getSubscription, cancelSubscription } from "../../utils/stripe";
import { navigate } from "gatsby-link";
import {
  head as _head,
  filter as _filter,
  get as _get,
  isEmpty as _isEmpty,
} from "lodash";
import AppStoreButtons from "../../components/AppStoreButtons";

const ManageSubscriptionPage = () => {
  const [state, setState] = React.useState({
    isFetchingSubscriptions: true,
    activeSubscription: null,
    subscriptions: {},
    stripeSubscription: null,
    confirmCancelStripe: false,
    stripeSubscriptionCancelled: false,
    stripeSubscriptionCancelFailed: false,
  });

  React.useEffect(() => {
    hasSubscriptions();
  }, []);

  const hasSubscriptions = async () => {
    let user = null;
    try {
      user = await getUser();
    } catch (e) {
      console.log(e);
      console.log("go back to join");
      navigate(`/join`);
    }
    try {
      const subscriptions = await isUserSubscribed(user.username);
      if (!subscriptions) {
        navigate(`/join`);
      }
      const subscription = _head(
        _filter(subscriptions, (subscription) => subscription.is_active)
      );

      if (!subscription) {
        navigate(`/join`);
      }

      let stripeSubscription = null;

      if (subscription.store === "web") {
        const getSubscriptionResponse = await getSubscription();
        stripeSubscription = _get(getSubscriptionResponse, "data", null);
        stripeSubscription = _isEmpty(stripeSubscription)
          ? null
          : stripeSubscription;
      }

      setState({
        ...state,
        isFetchingSubscriptions: false,
        activeSubscription: subscription,
        subscriptions: subscriptions,
        stripeSubscription: stripeSubscription,
        profile: user.attributes,
      });
    } catch (e) {
      console.log("Could not fetch subscriptions");
      console.log(e);
    }
  };

  const onConfirmCancelStripe = () => {
    setState({
      ...state,
      confirmCancelStripe: true,
    });
  };

  const onDisableOnConfirmCancelStripe = () => {
    setState({
      ...state,
      confirmCancelStripe: false,
    });
  };

  const onCancelSubscription = async () => {
    const subscriptionId = state.stripeSubscription.id;
    try {
      const cancelSubscriptionResponse = await cancelSubscription(
        subscriptionId
      );

      if (cancelSubscriptionResponse.status === 200) {
        setState({
          ...state,
          stripeSubscriptionCancelled: true,
        });
      } else {
        setState({
          ...state,
          stripeSubscriptionCancelFailed: true,
        });
      }
    } catch (e) {
      setState({
        ...state,
        stripeSubscriptionCancelFailed: true,
      });
    }
  };

  return (
    <Layout>
      <SEO title="Manage Subscription" />
      <Container fluid className="manage-page bg-gradient section">
        <Row>
          <Col className="left-col">
            {state.isFetchingSubscriptions && (
              <h2 className="text-center">Loading subscriptions...</h2>
            )}
            {!state.isFetchingSubscriptions && (
              <Col className="text-center">
                <h2>
                  Hi {_get(state.profile, "given_name", "")}{" "}
                  {_get(state.profile, "family_name", "")}
                </h2>

                <h2>
                  You have an active {state.activeSubscription.store}{" "}
                  subscription for
                </h2>

                <h3>nobs</h3>
                <h4>Your user is {_get(state.profile, "email", "")}</h4>
                <AppStoreButtons />
                <div className="subscription-messages">
                  <p>
                    Started on{" "}
                    {new Date(
                      state.activeSubscription.purchase_date
                    ).toLocaleString()}
                  </p>
                  <p>
                    Current period finishes on{" "}
                    {new Date(
                      state.activeSubscription.expires_date
                    ).toLocaleString()}
                  </p>
                  {state.activeSubscription.unsubscribe_detected_at && (
                    <p>
                      You have cancelled the subscription. It will end at the
                      end of this period.
                    </p>
                  )}
                  {state.activeSubscription.store === "web" &&
                    !state.activeSubscription.unsubscribe_detected_at &&
                    !state.confirmCancelStripe &&
                    state.stripeSubscription && (
                      <p>
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={onConfirmCancelStripe}
                        >
                          Cancel subscription
                        </button>
                      </p>
                    )}
                  {state.activeSubscription.store === "web" &&
                    !state.activeSubscription.unsubscribe_detected_at &&
                    !state.confirmCancelStripe &&
                    !state.stripeSubscription && (
                      <p>
                        If you subscribed via website contact with our{" "}
                        <a href="https://support.nobsapp.co.uk/hc/en-us">
                          support center
                        </a>
                        .
                      </p>
                    )}
                  {state.confirmCancelStripe &&
                    !state.stripeSubscriptionCancelled &&
                    !state.stripeSubscriptionCancelFailed && (
                      <div className="confirmCancelStripe">
                        <p>
                          Are you sure you want to cancel the Subscription?
                          <button
                            className="btn btn-warning"
                            onClick={onCancelSubscription}
                          >
                            Yes, please.
                          </button>{" "}
                          <button
                            className="btn btn-secondary"
                            onClick={onDisableOnConfirmCancelStripe}
                          >
                            Noooo!
                          </button>
                          <br />
                          <br /> It can take up to 2 hours to be cancelled.
                        </p>
                      </div>
                    )}
                  {state.stripeSubscriptionCancelled && (
                    <p>
                      Your subscription has been cancelled. It can take up to 2
                      hours to be shown here.
                    </p>
                  )}
                  {state.stripeSubscriptionCancelFailed && (
                    <p>
                      There has been an error cancelling the subscription.
                      Please, contact our support center
                    </p>
                  )}
                  {state.activeSubscription.store !== "web" && (
                    <p>
                      If you subscribed via the App Store or Play Store and you
                      wish to cancel your current subscription you will need to
                      do so from within the respective store.
                    </p>
                  )}
                </div>
              </Col>
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ManageSubscriptionPage;
